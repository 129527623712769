<template>
  <div class="idcard-proving">
    <div class="content">
      <div class="card-title">上传<span> "{{username}}" </span>本人的身份证照片</div>
      <div class="container">
        <div class="idPhotoBox clearfix ">
          <div class="idPhotoFront fl">
            <div class="show">
              <img ref="previewFront"
                   class="previewFront"
                   src="@/assets/img/stationagent/previewFront.png"
                   alt="" />
            </div>
            <div class="camera1"
                 v-if="camera1">
              <img :src=previewFrontBtnImg
                   alt=""
                   v-if="camera1" />
              <div class="loading"
                   v-if="loading1">
                <i></i>
              </div>
            </div>
            <input class="upload uploadFront"
                   type="file"
                   accept="image/*"
                   :disabled="frontDisabled"
                   @change="uploadFront" />
          </div>
          <p class="kapa">{{ takePhoto1 }}</p>
          <!-- <div class="titCont">
            <h5 class="fl">
              <input class="tit-upload"
                     type="file"
                     accept="image/*"
                     capture="camera"
                     :disabled="frontDisabled"
                     @change="uploadFront" />
            </h5>
          </div> -->
          <div class="idPhotoBack fl">
            <div class="show">
              <img ref="previewBack"
                   class="previewBack"
                   src="@/assets/img/stationagent/previewBack.png"
                   alt="" />
            </div>
            <div class="camera2"
                 v-if="camera2">
              <img :src=previewBackBtnImg
                   alt=""
                   v-if="camera2" />
              <div class="loading"
                   v-if="loading2">
                <i></i>
              </div>
            </div>
            <input class="upload uploadBack"
                   type="file"
                   accept="image/*"
                   :disabled="backDisabled"
                   @change="uploadBack"
                   @click="backClickFunc" />
          </div>
          <p class="kapa">{{ takePhoto2 }}</p>
        </div>
        <!--        <div class="tip"-->
        <!--             v-show="tipText">{{ tipText }}</div>-->
        <!-- <div class="titCont">
          <h5 class="fl"
              >
            <input class="tit-upload"
                   type="file"
                   accept="image/*"
                   capture="camera"
                   :disabled="backDisabled"
                   @change="uploadBack"
                   @click="backClickFunc" />
          </h5>
        </div> -->
      </div>
      <div class="card-desc"
           v-show="cardDescFlag">
        <div class="card-desc__hd">重要提示：</div>
        <div class="card-desc__bd flex">
          <div class="item">
            <div>建议使用纯色背景</div>
            <div>避免反光拍照</div>
          </div>
          <div class="item">
            <div>避免身份证上有异物</div>
            <div>保证拍摄图像清晰</div>
          </div>
        </div>
      </div>
      <div v-show="!cardDescFlag"
           class="card-desc-info">
        <van-field label="姓名"
                   placeholder="请输入姓名"
                   right-icon="edit"
                   v-model="info.username"
                   maxLength="20"
                   :formatter="nameFormatter" />
        <van-field :formatter="IdFormatter"
                   maxLength="18"
                   :readonly="isReadOnlyIDNO"
                   label="身份信息"
                   placeholder="身份信息"
                   v-model="info.idcard" />
      </div>
    </div>
    <div class="info-desc">请拍摄身份证正反面，信息仅用于身份认证</div>
    <div class="publicBtnDiv">
      <van-button :class="{ 'publicBtn': disabled, 'publicBtnActive': !disabled }"
                  :disabled="disabled"
                  type="info"
                  color="#3F7C53"
                  @click="nextpage">下一步</van-button>

    </div>

  </div>
</template>

<script>
import common from "@/utils/common";
import { Field, Toast, Button, Popup, Picker, Area, DatetimePicker, Checkbox, Dialog } from "vant";
import { saveIdentityInfo, getAgreementInfoByType, sendOcrPic, getMineInfo } from "@/utils/api";
import {appAction, uwStatisticAction} from "../../utils/util";
import { AUTH_SUBMIT } from "../../utils/constantNum";
export default {
  name: 'idcardProving',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Area.name]: Area,
    [DatetimePicker.name]: DatetimePicker,
    [Checkbox.name]: Checkbox,
    [Dialog.name]: Dialog,
  },
  watch: {
    info: {
      handler (val) {
        //
        if (
          !val.username ||
          !val.idcard ||
          !common.nameVerify(val.username) ||
          !common.IdCardVerify(val.idcard) ||
          !val.frontVaild ||
          !val.backVaild
        ) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      },
      deep: true
    }
  },
  data () {
    return {
      username: '',
      isReadOnlyIDNO: true,
      imgBaseUrl: '',
      imgEndUrl: '',
      title: "身份信息",
      disabled: true,
      cardDescFlag: true,
      frontDisabled: false,
      backDisabled: false,
      frontFlag: false,
      backFlag: false,
      fontImag: null,
      backImag: null,
      base64: "",
      takePhoto1: "点击上传带头像一面",
      takePhoto2: "点击上传带国徽一面",
      tipText: "",
      camera1: true,
      camera2: true,
      loading1: false,
      loading2: false,
      rightFlag1: false,
      wrongFlag1: false,
      rightFlag2: false,
      wrongFlag2: false,
      filename: null,
      info: {
        username: null,
        idcard: null,
        frontVaild: false,
        backVaild: false
      },
      checked: false,
      previewFrontBtnImg: require('@/assets/img/stationagent/camera.png'),
      previewBackBtnImg: require('@/assets/img/stationagent/camera.png')
    };
  },
  methods: {
    toGetMineInfo () {
      getMineInfo({
        'uniqueNo': common.getItem('uniqueNo'),
      }).then(res => {
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.username = res.data.name
          }
        }
      }).catch(() => {
      })
    },
    nameFormatter (val) {
      return val.replace(/[^\u4e00-\u9fa5.]/g, '')
    },
    IdFormatter (val) {
      return val.replace(/[^\w.v]/ig, "")

    },
    // 阻止先上传身份证国徽面
    backClickFunc (e) {
      if (!this.frontFlag) {
        this.tipText = "请先上传身份证人面像";
        this.backDisabled = true;
        e.preventDefault();
      }
    },
    // 图片压缩
    compress (img) {
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      let width = img.width;
      let height = img.height;
      // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio;
      if ((ratio = (width * height) / 4000000) > 1) {
        ratio = Math.sqrt(ratio);
        width /= ratio;
        height /= ratio;
      } else {
        ratio = 1;
      }
      canvas.width = width;
      canvas.height = height;
      context.fillStyle = "#fff";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 0, 0, width, height);
      this.base64 = canvas.toDataURL("image/jpeg", 0.1);
      // console.log(this.base64);
      canvas = null;
    },
    // 身份证人面像
    uploadFront (e) {
      this.filename = e.target.files[0].name
        ? e.target.files[0].name
        : "defaultname";
      this.idShow = false;
      this.rightFlag1 = false;
      this.wrongFlag1 = false;
      this.rightFlag2 = false;
      this.wrongFlag2 = false;
      this.nameText = "";
      this.IDCardText = "";
      this.takePhoto2 = "点击拍摄";
      this.$refs.previewBack.src = require("@/assets/img/stationagent/previewBack.png");
      this.takePhoto1 = "正在上传";
      let file = e.target.files[0];
      if (!e.target.files.length) {
        Toast("请上传图片");
        this.frontFlag = false;
        this.takePhoto1 = "点击上传带头像一面";
        this.$refs.previewFront.src = require("@/assets/img/stationagent/previewFront.png");
        return;
      }
      if (file.size > 15 * 1024 * 1024) {
        Toast("图片不能超过15M");
        this.frontFlag = false;
        this.takePhoto1 = "图片不能超过15M,请重新拍摄身份证人像面";
        this.$refs.previewFront.src = require("@/assets/img/stationagent/previewFront.png");
        return;
      }
      if (!common.imageVerify(file.type)) {
        Toast("图片格式错误");
        this.frontFlag = false;
        this.takePhoto1 = "图片格式错误，请重新拍摄身份证人像面";
        this.$refs.previewFront.src = require("@/assets/img/stationagent/previewFront.png");
        return;
      }
      this.tipText = "正在上传，请稍等";
      this.camera1 = false;
      this.loading1 = true;
      let reader = new FileReader();
      let _this = this;
      reader.onload = function (e) {
        _this.frontResult = e.target.result;
        _this.frontImg = new Image();
        _this.frontImg.src = _this.frontResult;
        _this.frontImg.onload = _this.frontVerify;
      };
      reader.readAsDataURL(file);
    },
    // 验证身份证人面像
    frontVerify () {
      let _this = this;
      this.frontDisabled = true;
      // 图片压缩
      this.compress(_this.frontImg);
      // this.$refs.previewFront.src = _this.base64;
      let params = {
        fileName: this.filename,
        fbFlag: "front",
        // productNo: "pro001",
        // token: common.getItem("tokenh5"),
        fileContent: this.base64
      };
      sendOcrPic(params)
        .then(res => {
          if (res.code === 200) {
            let data
            if (res.encryptedStatus === '1') {
              data = JSON.parse(common.decrypt(res.data, res.timestamp));
            } else {
              data = res.data
            }
            if (data.businessCode === 200) {
              // 验证姓名、身份证
              if (!common.nameVerify(data.userName)) {
                this.wrongFlag1 = true;
                this.rightFlag1 = false;
                this.info.frontVaild = false;
                this.loading1 = false;
                this.camera1 = true;
                this.takePhoto1 = "上传失败，请重新拍摄身份证人像面";
                this.frontDisabled = false; // 用户可点击
                Toast("上传失败，请重新拍摄身份证人像面");
                this.tipText = "上传失败，请重新拍摄身份证人像面";
                return;
              } else {
                this.wrongFlag1 = false;
                this.rightFlag1 = true;
                this.info.username = data.userName;
              }
              if (!common.IdCardVerify(data.idCard)) {
                this.wrongFlag2 = true;
                this.rightFlag2 = false;
                this.info.frontVaild = false;
                Toast("身份证号码不合法，请重新拍摄身份证人像面");
                this.loading1 = false;
                this.camera1 = true;
                this.takePhoto1 = "身份证号码不合法，请重新拍摄身份证人像面";
                this.frontDisabled = false; // 用户可点击
                this.tipText = "身份证号码不合法，请重新拍摄身份证人像面";
                return;
              } else {
                this.wrongFlag2 = false;
                this.rightFlag2 = true;
                this.info.idcard = data.idCard;
              }
              this.tipText = "上传成功";
              this.takePhoto1 = "点击重新上传带头像一面";
              this.previewFrontBtnImg = require('@/assets/img/stationagent/reloadCamera.png')
              this.cardDescFlag = false;
              this.backDisabled = false;
              this.frontFlag = true;
              this.info.frontVaild = true;
              this.camera1 = false
              this.$refs.previewFront.src = this.imgBaseUrl + data.filePath + this.imgEndUrl
            } else {
              this.info.username = "";
              this.info.idcard = "";
              this.isReadOnlyIDNO = false
              this.tipText = "上传失败";
              this.takePhoto1 = "上传失败,请重新上传身份证人像面";
              this.info.frontVaild = false;
              this.$refs.previewFront.src = require("@/assets/img/stationagent/previewFront.png");
            }
          } else {
            this.info.username = "";
            this.info.idcard = "";
            this.isReadOnlyIDNO = false
            this.tipText = "上传失败";
            this.info.frontVaild = false;
            this.$refs.previewFront.src = require("@/assets/img/stationagent/previewFront.png");
          }
          console.log('gggggg')
          this.loading1 = false;
          this.camera1 = true;
          this.takePhoto1 = "点击重新上传带头像一面";
          this.frontDisabled = false; // 用户可点击
        })
        .catch(() => {
          console.log('444')
          this.frontDisabled = false; // 用户可点击
          this.tipText = "上传失败";
          this.$refs.previewFront.src = require("@/assets/img/stationagent/previewFront.png");
          this.loading1 = false;
          this.camera1 = true;
          this.info.frontVaild = false;
          this.takePhoto1 = "上传失败,请重新上传身份证人像面";
        });
    },
    uploadBack (e) {
      let file = e.target.files[0];
      if (!e.target.files.length) {
        return;
      }
      this.backTimeStart = new Date().getTime();
      if (!this.frontFlag) {
        this.tipText = "请先上传身份证人面像";
        this.backDisabled = true;
        e.preventDefault();
      }
      if (!e.target.files.length) {
        Toast('请上传图片')
        this.backFlag = false
        this.$refs.previewBack.src = '/static/img/previewBack.png'
        this.takePhoto2 = '上传失败,请重新上传身份证国徽面'
        this.tipText = '上传失败'
        return
      }
      if (file.size > 15 * 1024 * 1024) {
        Toast("图片不能超过15M");
        this.backFlag = false;
        this.takePhoto2 = "图片不能超过15M,请重新上传身份证国徽面";
        this.$refs.previewBack.src = require("@/assets/img/stationagent/previewBack.png");
        this.tipText = '上传失败'
        return;
      }

      this.filename = e.target.files[0].name
        ? e.target.files[0].name
        : "defaultname";
      this.tipText = "正在上传，请稍等";
      this.camera2 = false;
      this.loading2 = true;
      this.takePhoto2 = "正在上传";
      let _this = this;
      // 文件上传
      let reader = new FileReader();
      reader.onload = function (e) {
        _this.backResult = e.target.result;
        _this.backImg = new Image();
        _this.backImg.src = _this.backResult;
        _this.backImg.onload = _this.backVerify;
      };
      reader.readAsDataURL(file);
    },
    // 验证身份证国徽面
    backVerify () {
      let _this = this;
      _this.backDisabled = true;
      // 图片压缩
      _this.compress(_this.backImg);
      _this.$refs.previewBack.src = _this.base64;

      let params = {
        fileName: this.filename,
        fbFlag: "back",
        // productNo: "pro001",
        // token: common.getItem("tokenh5"),
        fileContent: this.base64
      };
      sendOcrPic(params)
        .then(res => {
          if (res.code === 200) {
            let data
            if (res.encryptedStatus === '1') {
              data = JSON.parse(common.decrypt(res.data, res.timestamp));
            } else {
              data = res.data
            }

            if (data.businessCode === 200) {
              this.tipText = "上传成功";
              this.previewBackBtnImg = require("@/assets/img/stationagent/reloadCamera.png");
              this.info.backVaild = true;
              this.$refs.previewBack.src = this.imgBaseUrl + data.filePath + this.imgEndUrl
            } else {
              this.tipText = "上传失败";
              this.$refs.previewBack.src = require("@/assets/img/stationagent/previewBack.png");
              this.info.backVaild = false;
            }
            this.takePhoto2 = "点击重新上传带国徽一面";
            this.frontFlag = true;
            this.backDisabled = false;


          } else {
            // Toast(res.message);
            this.tipText = "上传失败";
            this.info.backVaild = false;
            this.$refs.previewBack.src = require("@/assets/img/stationagent/previewBack.png");
          }
          this.loading2 = false;
          this.camera2 = true;
          this.takePhoto2 = "点击重新上传带国徽一面";
          this.frontDisabled = false; // 用户可点击
        })
        .catch(() => {
          this.frontDisabled = false; // 用户可点击
          this.tipText = "上传失败";
          this.$refs.previewBack.src = require("@/assets/img/stationagent/previewBack.png");
          this.loading2 = false;
          this.camera2 = true;
          this.info.backVaild = false;
          this.takePhoto2 = "上传失败,重新上传身份证国徽面";
        });
    },
    nextpage () {
      Dialog.confirm({
        message: `<div>若信息有误将影响申请结果，请确认</div><div style="font-size:0.35rem; color:#222;margin-top:0.2rem;">${this.info.username}</div><div style="font-size:0.35rem; color:#222;margin-top:0.2rem;">${this.info.idcard}</div>`,
        confirmButtonText: '确认无误',
        cancelButtonText: '返回修改'
      }).then(() => {
        let params = {
          name: this.info.username,
          idCard: this.info.idcard,
        };
        saveIdentityInfo(params)
          .then(res => {
            if (res.code === 200) {
              let data
              if (res.encryptedStatus === '1') {
                data = JSON.parse(common.decrypt(res.data, res.timestamp));
              } else {
                data = res.data

              }
              if (data.businessCode === 200) {
                Toast(data.businessMessage);
                common.setItem('userName', this.info.username)
                this.$store.dispatch('keepAliveState/addKeepAliveAction', 'cardInfo')
                this.$router.push({ name: "businessLicense", query: { name: this.info.username } });
                if (common.getItem('jumpFrom') != undefined ) {
                  appAction(AUTH_SUBMIT)
                }
              }
            }
          })
          .catch(err => { });
        // on confirm
      }).catch(() => {
        // on cancel
      })

    }
  },
  mounted () {
    if (this.$route.query.jumpFrom != undefined) {
      this.$store.dispatch('keepAliveState/addKeepAliveAction', 'idcardProving')
    }

    if (process.env.NODE_ENV == 'production') {
      this.imgBaseUrl = window.location.origin + '/file/'
      this.imgEndUrl = '?Authorization=' + common.getItem('wtToken')
    } else {
      this.imgBaseUrl = 'hhttp://124.70.167.116:20081' + '/file/'
      this.imgEndUrl = '?Authorization=' + common.getItem('wtToken')
    }
    if (common.getItem('uniqueNo') != undefined) {
      this.toGetMineInfo();
    }
    // let url = 'https://sit-villagestation.wowfintech.net/file/group1/M00/00/01/rBcqh158TMSADty8AAA_Lby1_HM34.jpeg? Authorization='+ common.getItem('wtToken')
    /*  let name ='张三'
      let idcard = 510524198002071164
      Dialog.confirm({
        message: `<div>若信息有误将影响申请结果，请确认</div><div style="font-size:0.35rem; color:#222;margin-top:0.2rem;">${name}</div><div style="font-size:0.35rem; color:#222;margin-top:0.2rem;">${idcard}</div>`,
        confirmButtonText: '确认无误',
        cancelButtonText: '返回修改'
      }).then(() => {
        // on confirm
      }).catch(() => {
        // on cancel
      });*/
    uwStatisticAction('/idcardProving','站长身份证OCR')
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == 'cardInfo') {
    } else {
      this.$store.dispatch('keepAliveState/removeKeepAlive', 'idcardProving')
    }
    next()
  },
};
</script>

<style lang="less" scoped>
.idcard-proving {
  background-color: #F5F5F5;
  padding-bottom: 100px;
  #loading {
    background: rgba(0, 0, 0, 0.3);
  }
  .flex {
    display: flex;
  }
  .card-title {
    text-align: center;
    font-size: 13px;
    padding: 15px 15px;
    color: #999;
    span {
      color: #272829;
    }
  }
  .info-desc {
    text-align: center;
    color: #F4664A;
    font-size: 0.26rem;
    margin-bottom: 0.24rem;
  }
  .content {
    background-color: #F5F5F5;
    .card__flex {
      justify-content: space-around;
    }
    .idcard-wrap {
      position: relative;
      .upload {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 50;
        opacity: 0;
      }
    }

    .button-wrap {
      margin-top: 0.2rem;
      .van-button {
        width: 2.94rem;
        height: 0.56rem;
        line-height: 0.48rem;
        border-radius: 0.12rem;
        font-size: 0.28rem;
      }
      .van-button--normal {
        padding: 0;
      }
    }

    .card-desc {
      color: #999999;
      font-size: 0.3rem;
      margin-top: 0.5rem;
      margin-bottom: 0.24rem;
      .card-desc__hd {
        margin: 0.2rem;
      }
      .card-desc__bd {
        margin-left: 0.5rem;
        .item {
          flex: 1;
          justify-content: space-between;
          color: #aaa;
          div {
            line-height: 0.5rem;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              width: 0.1rem;
              height: 0.1rem;
              background: #aaa;
              border-radius: 50%;
              top: 36%;
              left: -6%;
            }
          }
        }
      }
    }
    .card-desc-info {
      padding: 0.4rem 0.3rem;
    }
  }
  .container {
    background-color: #F5F5F5;
    padding: 0rem 0.35rem 0;

    .titCont {
      display: flex;
      justify-content: space-between;
      // padding: 0rem 0.2rem;
      h5 {
        width: 6.8rem;
        height: 0.56rem;
        line-height: 0.56rem;
        /*width: 50%;*/
        font-size: 0.28rem;
        color: #fff;
        background: #f5f5f5;
        text-align: center;
        /*padding: 0.2rem 0;*/
        border-radius: 0.12rem;
        position: relative;
        .tit-upload {
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
    .idPhotoBox {
      .idPhotoFront,
      .idPhotoBack {
        width: 100%;
        height: 4.25rem;
        position: relative;
        display: flex;
        .show {
          width: 6rem;
          height: 3.5rem;
          overflow: hidden;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .camera1,
        .camera2 {
          width: 1.6rem;
          height: 1.6rem;
          position: absolute;
          display: flex;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 1rem;
          img {
            width: 1rem;
            height: 0.86rem;
          }
        }
        .loading {
          position: absolute;
          left: 50%;
          top: 0;
          transform: translate(-50%);
        }
        .upload {
          width: 3.14rem;
          height: 100%;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translate(-50%);
          opacity: 0;
        }
      }
    }
    .kapa {
      color: #3F7C53;
      text-align: center;
      font-size: 0.3rem;
    }
    .tip {
      height: 0.6rem;
      line-height: 0.6rem;
      font-size: 0.26rem;
      background: #e02020;
      color: #fff;
      text-align: center;
      margin-top: 0.2rem;
    }
  }
  .button-desc {
    padding: 0.2rem 0.3rem 0.1rem;
    font-size: 0.24rem;
    color: #999999;
    align-items: center;
    div {
      margin-left: 0.1rem;
    }
    span {
      color: #3F7C53;
    }
  }
  .footer {
    // height: 1.38rem;
    // flex: 0 0 1.38rem;
    // display: flex;
    // justify-content: center;
    height: 0.88rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .van-button {
      width: 100%;
    }
  }
}
</style>
